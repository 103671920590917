// src/types.ts
export interface Segment {
    segmentid: number;
    segmentname: string;
}

export enum UserRole {
  Admin = 1,
  Seller = 3,
  ProductAdmin = 2,
  Finance = 4
}

export interface UserIdentity {
    id: string;
    fullName: string;
    role_id: UserRole;
    role: string;
    avatar?: string;
}

export interface ICategory {
    id: number;
    category_name: string;
    category_description: string;
}

export interface IOffer {
  id: number;
  post: string;
  expirydate: Date | string; // Date type can be used for date objects and string for ISO date strings
}

export interface ISubCategory {
    id: number;
    parent_id: number;
    sub_category_name: string;
    sub_category_description: string;
    category_name: string;
    category_id:number;
    parentCategory?: ICategory;  
  }

  export interface IProductSegment {
    id: number;
    product_segment_name: string;
    product_segment_description: string;
  }

  export interface ICityShipping {
    id: number;
    city_from: number;
    city_to: number;
    city_from_name: string;
    city_to_name: string;
  }

  export interface IProduct {
    id: number;
    category_id: number;
    category_name: string;
    sub_category_id: number;
    sub_category_name: string;
    product_name: string;
    product_grade: string;
    product_mfi: string;
    current_price: string;
    product_segments?: string;
  }

  export interface IProductPricing {
    id: number;
    seller_id: number;
    category_name: string;
    sub_category_name?: string;
    product_name: string;
    product_grade: string;
    product_mfi: string;
    price?: number; 
    price_date?: Date; 
    user_id: number;
    product_id: number;
  }

  export interface IUser {
    id: number;
    user_name: string;
    user_first_name: string;
    user_last_name: string;
    email_address: string;
    address_line1: string;
    address_line2?: string; 
    address_city?: string;
    address_state?: string;
    address_country?: string;
    address_pin?: string;
    phone_landline?: string; 
    phone_mobile?: string; 
    gst_number?: string; 
    gst_registration_year?: number; 
    delivery_contact_person?: string;
    delivery_address_line1?: string;
    delivery_address_line2?: string; 
    delivery_address_city?: string;
    delivery_address_state?: string;
    delivery_address_country?: string;
    delivery_address_pin?: string;
    payment_terms?: string;
    has_newsletter?: boolean;
    avatar_url?: string; 
    segment_id?: number; 
    role_id?: number; 
    company_name?: string; 
}
export interface IState {
  id: number;
  state_name: string;
  state_code: string;
  zone: string;
  active: boolean;
}

export interface IUserCities {
  id: number;
  city_name: string;
}

export interface IOrder {
  id: number;
  order_date: string; 
  order_customer_id: number;
  order_quantity: number;
  order_price: number; 
  order_commission: number;
  order_delivery_charges: number;
  order_status_id: number;
  order_tracking_url: string;
  order_product_id: number;
  order_total: number;
  customer_contact : string;
  product_name :string;
  product_grade : string;
  history_count : string;
}

export interface IOrderHistory {
  id: number;
  order_id: number;
  order_name: string;
  order_description: string;
  user_id: number;
  order_sub_status_id: number;
  comments:string;
  comment_date:Date;
  comment_user_id: number;
  order_sub_status : string;
  phone_mobile : string;
  user_name : string;
}


export interface IBlog {
  id: number;
  blog_name: string;
  blog_description: string;
  image?: File; 
}


export interface BlogParams {
  id: string;
  title?: string;
  description?: string;
  image?: {
    rawFile: File;
    src: string;
    title: string;
  };
}