// src/api/routes.js
const API_ROUTES = {
    segments: '/segments',
    login: '/auth/login', 
    products: '/products',
    dashboard: '/dashboard',
    messages: '/messages',
    orders: '/orders',
    orderhistory: '/orderhistory',
    sellerfilter: '/sellerFilter/',
    unregistered: '/unregistered',
    blogs:'/blogs',
};

export default API_ROUTES;
