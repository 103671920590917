import * as React from 'react';

import { FC, useState, Fragment } from 'react';
import {
    List, Datagrid,
    TextField, EditButton,
    useNotify,useDelete,
    ListProps, Button as RAButton,
    useRecordContext,
    TextInput,
    RichTextField,
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IBlog } from '../types/types';
import useAuthorize from '../hooks/useAuthorize';
import { UserRole } from '../types/types';

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<IBlog>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('blogs', { id: record.id });
        notify('resources.blogs.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Blog' }
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this blog?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};


const blogFilter = [
    <TextInput label="Blogs" source="title" resettable alwaysOn/>,
];

export const BlogList: FC<ListProps> = (props) => {
    useAuthorize([UserRole.Admin]);
    return (
        <List {...props} sort={{ field: "created_at", order: "DESC" }} filters={blogFilter}>
        <Datagrid>
            <TextField source="title" label="Blog Name" />
            <RichTextField source="description" label="Description" />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
    );
};