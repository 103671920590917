import * as React from 'react';
import { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DensityMediumOutlinedIcon from '@mui/icons-material/DensityMediumOutlined';
import SegmentOutlinedIcon from '@mui/icons-material/SegmentOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { UserRole } from '../types/types'; 
import axios from 'axios';
import API_ROUTES from '../routes/routes'; 

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import SubMenu from './SubMenu';

type MenuName = 'menuProducts' |  'menuCustomers' | 'menuSales' | 'menuUsers' | 'menuShipping' | 'menuProfiles' | 'menuBlogs';


const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuProducts: true,
        menuUsers: true,
        menuShipping:true,
        menuProfiles:true,
        menuBlogs:true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const role_id = localStorage.getItem('user_role_id');
    const userRole = role_id ? parseInt(role_id) as UserRole : null; 
    const user_id =  localStorage.getItem('user_id');
    const [topCityId, setTopCityId] = useState(null);

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    async function fetchSellerData(userId: string) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${API_ROUTES.sellerfilter}${userId}`);
            console.log("response:",response);
            console.log("response.data:",response.data);
            return response.data;
        } catch (error) {
            console.error('There was a problem fetching seller data:', error);
        }
    }

    useEffect(() => {
        if (userRole !== undefined && userRole !== null && userRole === UserRole.Seller && user_id) {
            fetchSellerData(user_id).then(data => {
                if (data) {
                    setTopCityId(data.top_city_id);
                }
            });
        }
    }, [userRole, user_id]);

    
    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            
            {userRole === UserRole.Admin && (
                <>
                 <DashboardMenuItem />
                 
                <SubMenu
                    handleToggle={() => handleToggle('menuProducts')}
                    isOpen={state.menuProducts}
                    name="pos.menu.orders"
                    icon={<ListAltIcon />}
                    dense={dense}
                > 
                    <MenuItemLink
                        to={`/orders`}
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.orders.name`, { smart_count: 2 })}
                        leftIcon={<ListAltIcon />}
                        dense={dense}
                    />
                </SubMenu>
               
                    <SubMenu
                        handleToggle={() => handleToggle('menuProducts')}
                        isOpen={state.menuProducts}
                        name="pos.menu.products"
                        icon={<Inventory2OutlinedIcon />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to="/sellerfilter"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.sellerfilter.name`, { smart_count: 2 })}
                            leftIcon={<CurrencyRupeeIcon />}
                            dense={dense}
                        />

                        <MenuItemLink
                            to="/products"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.products.name`, { smart_count: 2 })}
                            leftIcon={<Inventory2OutlinedIcon />}
                            dense={dense}
                        />
                        <MenuItemLink
                            to="/categories"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.categories.name`, { smart_count: 2 })}
                            leftIcon={<DensityMediumOutlinedIcon />}
                            dense={dense}
                        />
                        <MenuItemLink
                            to="/subcategories"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.subcategories.name`, { smart_count: 2 })}
                            leftIcon={<SegmentOutlinedIcon />}
                            dense={dense}
                        />
                        <MenuItemLink
                            to="/productsegments"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.productsegments.name`, { smart_count: 2 })}
                            leftIcon={<PieChartOutlineIcon />}
                            dense={dense}
                        />
                        <MenuItemLink
                            to="/offers"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.offers.name`, { smart_count: 2 })}
                            leftIcon={<LocalOfferOutlinedIcon />}
                            dense={dense}
                        />
                        

                    </SubMenu>
                    <SubMenu
                        handleToggle={() => handleToggle('menuCustomers')}
                        isOpen={state.menuCustomers}
                        name="pos.menu.customers"
                        icon={<PersonAddAltIcon />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to="/customers"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.customers.name`, { smart_count: 2 })}
                            leftIcon={<PersonAddAltIcon />}
                            dense={dense}
                        />
                        <MenuItemLink
                            to="/unregistered"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.unregistered.name`, { smart_count: 2 })}
                            leftIcon={<PersonAddAltIcon />}
                            dense={dense}
                        />
                    </SubMenu>
                    <SubMenu
                            handleToggle={() => handleToggle('menuShipping')}
                            isOpen={state.menuShipping}
                            name="pos.menu.shipping"
                            icon={<LocalShippingIcon />}
                            dense={dense}
                        >
                            <MenuItemLink
                                to="/states"
                                state={{ _scrollToTop: true }}
                                primaryText={translate(`resources.states.name`, { smart_count: 2 })}
                                leftIcon={<LocationOnIcon />}
                                dense={dense}
                            />
                            <MenuItemLink
                                to="/cityshipping"
                                state={{ _scrollToTop: true }}
                                primaryText={translate(`resources.cityshipping.name`, { smart_count: 2 })}
                                leftIcon={<LocalShippingIcon />}
                                dense={dense}
                            />
                        </SubMenu>
                        <SubMenu
                            handleToggle={() => handleToggle('menuUsers')}
                            isOpen={state.menuUsers}
                            name="pos.menu.users"
                            icon={<PersonOutlineIcon />}
                            dense={dense}
                        >
                            <MenuItemLink
                                to={`/users`}
                                state={{ _scrollToTop: true }}
                                primaryText={translate(`resources.users.name`, { smart_count: 2 })}
                                leftIcon={<PersonOutlineIcon />}
                                dense={dense}
                            />
                        </SubMenu>
                        <SubMenu
                    handleToggle={() => handleToggle('menuBlogs')}
                    isOpen={state.menuBlogs}
                    name="pos.menu.blogs"
                    icon={<ListAltIcon />}
                    dense={dense}
                > 
                   <MenuItemLink
                            to="/blogs"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.blogs.name`, { smart_count: 2 })}
                            leftIcon={<DensityMediumOutlinedIcon />}
                            dense={dense}
                        />
                </SubMenu>
                        

                </>
            )}
            
            {userRole === UserRole.ProductAdmin && (
                <>
                <SubMenu
                    handleToggle={() => handleToggle('menuProducts')}
                    isOpen={state.menuProducts}
                    name="pos.menu.products"
                    icon={<Inventory2OutlinedIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={`/sellerfilter`}
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.productpricing.name`, { smart_count: 2 })}
                        leftIcon={<CurrencyRupeeIcon />}
                        dense={dense}
                    />
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggle('menuUsers')}
                    isOpen={state.menuUsers}
                    name="pos.menu.profile"
                    icon={<PersonOutlineIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={`/users/${user_id}`}
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.profile.name`, { smart_count: 2 })}
                        leftIcon={<PersonOutlineIcon />}
                        dense={dense}
                    />
                </SubMenu>
                </>
            )}


            {userRole === UserRole.Seller && (
                <>
                <SubMenu
                    handleToggle={() => handleToggle('menuProducts')}
                    isOpen={state.menuProducts}
                    name="pos.menu.products"
                    icon={<Inventory2OutlinedIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={topCityId ? `/productpricing?filter=${encodeURIComponent(JSON.stringify({ seller_id: user_id, city_id: topCityId }))}` : `/productpricing?filter={"seller_id":${user_id}}`}
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.productpricing.name`, { smart_count: 2 })}
                        leftIcon={<CurrencyRupeeIcon />}
                        dense={dense}
                    />
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggle('menuUsers')}
                    isOpen={state.menuUsers}
                    name="pos.menu.profile"
                    icon={<PersonOutlineIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={`/users/${user_id}`}
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`resources.profile.name`, { smart_count: 2 })}
                        leftIcon={<PersonOutlineIcon />}
                        dense={dense}
                    />
                </SubMenu>
                </>
            )}
        </Box>
    );
};

export default Menu;
