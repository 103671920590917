import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  ImageInput,
  ImageField,
  useCreate,
} from "react-admin";
import { Button } from "@mui/material";
import { useState } from "react";
import useAdmin from "../hooks/useAdmin";

const CustomToolbar = () => {
  const redirect = useRedirect();

  const handleBack = () => {
    redirect("/blogs");
  };

  return (
    <Toolbar>
      <SaveButton />
      <Button
        color="primary"
        onClick={handleBack}
        style={{ marginLeft: "10px" }}
      >
        Back
      </Button>
    </Toolbar>
  );
};

export const BlogCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [create] = useCreate();
  useAdmin();

  // const handleSubmit = async (values: any) => {
  //   try {
  //     // Create FormData object to handle file upload
  //     const formData = new FormData();
  //     formData.append("title", values.title);
  //     formData.append("description", values.description);

  //     // Handle image upload if present
  //     if (values.image && values.image.rawFile) {
  //       formData.append("image", values.image.rawFile);
  //     }

  //     // Make API call
  //     const response = await fetch(process.env.REACT_APP_API_URL + "/blogs", {
  //       method: "POST",
  //       body: formData,
  //       // Don't set Content-Type header - let browser set it with boundary for FormData
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to create blog");
  //     }

  //     const data = await response.json();

  //     // Show success notification
  //     notify("resources.blogs.notification.created", {
  //       type: "success",
  //       messageArgs: { smart_count: 1, name: "Blog" },
  //     });
  //     //   notify("resources.blogs.notification.created", { type: "success" });
  //     redirect("/blogs");
  //   } catch (error) {
  //     console.error("Error creating blog:", error);
  //     notify("Error creating blog", { type: "error" });
  //   }
  // };
  
  const handleSubmit = async (values:any) => {
    try {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description.replace(/\n/g, "<br/>"));

        if (values.image && values.image.rawFile) {
            formData.append("image", values.image.rawFile);
        }

        const response = await fetch(process.env.REACT_APP_API_URL + "/blogs", {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Failed to create blog");
        }

        notify("Blog created successfully!", { type: "success" });
        redirect("/blogs");
    } catch (error) {
        console.error("Error creating blog:", error);
        notify("Error creating blog", { type: "error" });
    }
};

const validateImageSizeAndType = (value: any): string | undefined => {
  console.log("Received value:", value);

  // Extract the actual File object
  const file = value?.rawFile;

  if (!file) {
    console.log("No file provided");
    return undefined;
  }

  console.log("Extracted file:", file);

  // File size validation (5MB limit)
  const maxSizeInBytes = 5 * 1024 * 1024;
  if (file.size > maxSizeInBytes) {
    console.log("File size exceeded");
    return "Image size should be a maximum of 5 MB";
  }

  // File type validation
  const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"];
  if (!allowedTypes.includes(file.type)) {
    console.log("Invalid file type");
    return "Only JPG, JPEG, PNG, GIF, or SVG files are allowed.";
  }

  return undefined;
};


  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolbar />} onSubmit={handleSubmit}>
        <TextInput
          source="title"
          label="Title"
          validate={required()}
          fullWidth
        />

        <TextInput
          source="description"
          label="Description"
          validate={required()}
          multiline
          fullWidth
          rows={4} // Sets the height of the input field
        />
        <ImageInput
          source="image"
          label="Related pictures"
          accept="image/*"
          validate={[required("Image is required"), validateImageSizeAndType]}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default BlogCreate;
