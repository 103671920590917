import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    Toolbar,
    SaveButton,
    useNotify,
    useRedirect,
    ImageInput,
    ImageField,
    useUpdate,
} from "react-admin";
import { Button } from "@mui/material";
import useAdmin from "../hooks/useAdmin";

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect("/blogs");
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: "10px" }}>
                Back
            </Button>
        </Toolbar>
    );
};

// Image Validation Function
const validateImageSizeAndType = (value: any): string | undefined => {
    console.log("Received value:", value);

    const file = value?.rawFile;

    if (!file) {
        console.log("No file provided");
        return undefined;
    }

    console.log("Extracted file:", file);

    // File size validation (5MB limit)
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
        console.log("File size exceeded");
        return "Image size should be a maximum of 5 MB";
    }

    // File type validation
    const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"];
    if (!allowedTypes.includes(file.type)) {
        console.log("Invalid file type");
        return "Only JPG, JPEG, PNG, GIF, or SVG files are allowed.";
    }

    return undefined;
};

export const BlogEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [update] = useUpdate();
    useAdmin();

    const handleSubmit = async (values: any) => {
        try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("description", values.description.replace(/\n/g, "<br/>"));

            if (values.image && values.image.rawFile) {
                formData.append("image", values.image.rawFile);
            } else if (values.existingImage) {
                formData.append("existingImage", values.existingImage);
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/blogs/${values.id}`, {
                method: "PUT",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to update blog");
            }

            notify("Blog updated successfully", { type: "success" });
            redirect("/blogs");
        } catch (error) {
            console.error("Error updating blog:", error);
            notify("Error updating blog", { type: "error" });
        }
    };

    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar />} onSubmit={handleSubmit}>
                <TextInput source="title" label="Title" validate={required()} fullWidth />

                <TextInput
                    source="description"
                    label="Description"
                    validate={required()}
                    multiline
                    fullWidth
                    rows={4}
                    parse={(value) => value.replace(/\n/g, "<br>")}
                    format={(value) => value?.replace(/<br\s*\/?>/g, "\n")}
                />

                <ImageInput
                    source="image"
                    label="Related pictures"
                    accept="image/*"
                    validate={validateImageSizeAndType}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export default BlogEdit;
