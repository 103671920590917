import React, { FC} from 'react';


import { List, Datagrid, TextField, EditButton, DeleteButton, TextInput, ListProps } from 'react-admin';
import useAdmin from '../hooks/useAdmin';

const CustomerFilter = [
    <TextInput label="Customer" source="customer_search" resettable alwaysOn/>,
];

export const CustomerList: FC<ListProps> = (props) => {

    useAdmin();
    return (
        <List filters={CustomerFilter} {...props}>
            <Datagrid>
                <TextField source="customer_username" label="User Name" />
                <TextField source="phone_mobile" label="Mobile Number" />
                <TextField source="company_name" label="Company Name" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    ); 
};

export default CustomerList;