import BlogIcon from '@mui/icons-material/Bookmark';

import {BlogList} from './BlogList';
import { BlogEdit } from './BlogEdit';
import { BlogCreate } from './BlogCreate';

export default {
    list: BlogList,
    edit: BlogEdit,
    create: BlogCreate,
    icon: BlogIcon,
};
